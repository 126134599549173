<template>
  <div class="page_box">
		<div class="shop_info">
			<div class="shop_name">{{taskInfo.title}}</div>
			<div class="info_item">任务平台：<span>{{taskInfo.platform_info && taskInfo.platform_info.name}}</span></div>
			<div class="info_item">任务编号：<span>{{taskInfo.task_number}}</span></div>
			<div class="info_item">发布时间：<span>{{taskInfo.shelf_at}}</span></div>
			<div class="info_item">发布时间：<span>{{taskInfo.address}}</span></div>
		</div>
		<div class="mode_content">
			<div class="mode_title">任务详情</div>
			<div class="task_content">
				<div class="content_title">任务内容</div>
				<div class="content_info">{{taskInfo.detail}}</div>
			</div>
		</div>
		<div class="mode_info">
			<div class="task_info" v-if="taskInfo.exceed_order_num && taskInfo.reward_price">
				<div class="info_left">多单奖励</div>
				<div class="info_right">超过{{taskInfo.exceed_order_num}}单，每单奖励{{taskInfo.reward_price/100}}元</div>
			</div>
			<div class="task_info">
				<div class="info_left">佣金</div>
				<div class="info_right">{{taskInfo.commission_price/100}}</div>
			</div>
			<div class="task_info">
				<div class="info_left">成长值</div>
				<div class="info_right">{{taskInfo.growth_value}}</div>
			</div>
			<div class="task_info" v-for="( couponsItem, couponsIndex ) in taskInfo.coupons" :key="couponsIndex+couponsItem" :data-clipboard-text="couponsItem" @click="copy('优惠券地址'+(couponsIndex+1))">
				<div class="info_left">
					<div class="coupon_text">优惠券地址{{couponsIndex+1}}</div>
					<div class="coupon_link">{{couponsItem}}</div>
				</div>
				<div class="info_right">点击复制</div>
			</div>
			<div class="task_info" v-for="( passwordsItem, passwordsIndex ) in taskInfo.passwords" :key="passwordsIndex" :data-clipboard-text="passwordsItem" @click="copy('口令'+(passwordsIndex+1))">
				<div class="info_left">
					<div class="coupon_text">口令{{passwordsIndex+1}}</div>
					<div class="coupon_link">{{passwordsItem}}</div>
				</div>
				<div class="info_right">点击复制</div>
			</div>
			<div class="task_info" v-if="taskInfo.remark">
				<div class="info_left remark_box">
					<div class="coupon_text">备注</div>
					<div class="coupon_link">{{taskInfo.remark}}</div>
				</div>
			</div>
		</div>
		<div class="repair_button" v-if="taskInfo.status != 1" @click="toSubmitTask">此任务已关闭，是否需要补单？</div>
		<div class="record_box">
			<div class="record_title">报单记录</div>
			<div class="record_item" v-for="(reItem, reindex) in taskInfo.reports" :key="reindex" @click="toOrderDetail(reItem.id)">
				<div class="record_top">报单编号：{{reItem.report_number}}</div>
				<div class="record_name">{{reItem.taskInfo && reItem.taskInfo.title}}</div>
				<div class="record_info">
					<div class="info_left">
						<div class="left_item">收货地址：<span>{{reItem.taskInfo && reItem.taskInfo.address}}</span></div>
						<div class="left_item">报单时间：<span>{{reItem.created_at}}</span></div>
						<div class="left_item">支付宝账号：<span>{{reItem.alipay_account}}</span></div>
					</div>
					<div class="record_statu" :style="reItem.status==0?'color:#1677FF;':reItem.status==1?'color:#07c160;':'color:red;'">{{reItem.status==0?"待审核":reItem.status==1?"已通过":"已驳回"}}</div>
				</div>
				<img class="item_ydk" v-if="reItem.payment_status == 1" src="@/assets/images/ydk.png"/>
			</div>
		</div>
		<div class="button_box">
			<button class="order_now" v-if="taskInfo.status == 1" type="button" @click="toSubmitTask">立即报单</button>
			<button class="order_closed" v-else type="button">任务已关闭</button>
		</div>
  </div>
</template>

<script>
	import { getTaskDetail } from '@/services/home';
	import Clipboard from 'clipboard'; 
	import { Toast } from 'vant'
	export default {
		data() {
			return {
				taskId: "",
				taskInfo: {}
			};
		},
		computed: {
		},
		mounted() {
			let params = this.$route.query
			this.taskId = params.taskId
			console.log(params)
			this.getTaskDetail()
		},
		methods: {
			getTaskDetail(){
				getTaskDetail({ id:this.taskId }).then(res => {
					if (res && res.code === 0) {
						// console.log(res)
						this.taskInfo = res.data
					}
				});
			},
			toSubmitTask() {
				this.$router.push({
					path: '/submitTask',
					query: {
						taskId: this.taskId,
					},
				})
			},
			copy(even) {  
				let clipboard = new Clipboard('.task_info')  
				clipboard.on('success', e => {
					Toast(even+' 复制成功');
					// 释放内存  
					clipboard.destroy()  
				})  
				clipboard.on('error', e => {  
					Toast(even+' 复制失败');
					clipboard.destroy()  
				})  
			},
			toOrderDetail(id) {
				this.$router.push({
					path: '/orderDetail',
					query: {
						id: id,
					},
				})
			},
		},
	};
</script>
<style lang="less" scoped>
	.page_box{
		padding-bottom: 130px;
	}
	.shop_info{
		background: #ffffff;
		padding: 20px;
		margin: 20px;
		border-radius: 8px;
		.shop_name{
			color: #333333;
			font-size: 32px;
			font-weight: bold;
			word-break: break-all;
			line-height: 40px;
			padding: 10px 0;
		}
		.info_item{
			line-height: 40px;
			font-size: 24px;
			color: #999999;
			span{
				color: #666666;
			}
		}
	}
	.mode_content{
		background: #ffffff;
		padding: 20px;
		margin: 20px;
		border-radius: 8px;
		.mode_title{
			color: #333333;
			font-weight: 500;
			line-height: 80px;
			font-size: 28px;
			border-bottom: 1px solid #CCCCCC;
		}
		.task_content{
			padding: 10px 0;
			.content_title{
				font-size: 28px;
				color: #666666;
				line-height: 60px;
			}
			.content_info{
				line-height: 35px;
				font-size: 26px;
				color: #999999;
				word-break: break-all;
			}
		}
	}
	.mode_info{
		background: #ffffff;
		padding: 20px;
		margin: 20px;
		border-radius: 8px;
		.task_info{
			border-bottom: 1px solid #CCCCCC;
			padding: 10px 0;
			background: #FFFFFF;
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 28px;
			.info_left{
				line-height: 40px;
				color: #666666;
				max-width: 460px;
				word-break: break-all;
				.coupon_text{
					line-height: 50px;
				}
				.coupon_link{
					color: #999999;
					line-height: 40px;
					word-break: break-all;
				}
			}
			.remark_box{
				max-width: 100%;
			}
			.info_left_s{
				line-height: 40px;
				color: #666666;
				word-break: break-all;
			}
			.info_right{
				line-height: 40px;
				color: #999999;
			}
		}
		:last-child{
			border: none;
		}
	}
	.button_box{
		position: fixed;
		width: 100vw;
		left: 0;
		bottom: 0;
		height: 130px;
		background: #FFFFFF;
		border-top: 1px solid #CCCCCC;
		display: flex;
		justify-content: center;
		align-items: center;
		.order_now{
			height: 98px;
			width: 690px;
			border-radius: 8px;
			color: #FFFFFF;
			font-size: 28px;
			background: @button-color;
		}
		.order_closed{
			height: 98px;
			width: 690px;
			border-radius: 8px;
			color: #999999;
			font-size: 28px;
			background: #cccccc;
		}
	}
	.record_box{
		padding: 10px 20px;
		.record_title{
			line-height: 80px;
			font-size: 32px;
		}
		.record_item{
			margin-bottom: 20px;
			background: #FFFFFF;
			border-radius: 8px;
			padding: 10px 20px;
			position: relative;
			.item_ydk{
				position: absolute;
				top: 20px;
				right: 20px;
				z-index: 1;
				width: 100px;
				height: 100px;
				opacity: 0.5;
			}
			.record_top{
				color: #999999;
				font-size: 28px;
				line-height: 60px;
				border-bottom: 1px solid #CCCCCC;
			}
			.record_name{
				padding: 20px 0;
				font-size: 30px;
				color: #333333;
				font-weight: 500;
				word-break: break-all;
				overflow: hidden;
				-webkit-line-clamp: 2;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				line-height: 40px;
			}
			.record_info{
				margin-top: 10px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				.info_left{
					font-size: 26px;
					color: #999999;
					max-width: 500px;
					.left_item{
						line-height: 40px;
						span{
							color: #666666;
						}
					}
				}
				.record_statu{
					font-size: 28px;
					color: #1677FF;
					font-weight: bold;
				}
			}
		}
	}
	.repair_button{
		text-align: center;
		line-height: 60px;
		margin: 10px 0;
		text-decoration: underline;
		color: #999999;
		font-size: 28px;
	}
</style>
