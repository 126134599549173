<template>
  <div class="page_box">
		<van-notice-bar
			v-if="companyInfo.notification"
		  left-icon="volume-o"
		  :text="companyInfo.notification"
		/>
		<div class="mode_top" @click="toUserInfo">
			<div class="top_left">
				<img class="user_avatar" :src="userInfo.avatar" >
				<div class="user_info">
					<div class="user_name">
						{{userInfo.nickname}}
						<div class="user_grade">{{userInfo.level == 1 ? '初级会员': userInfo.level == 2 ? '中级会员':'高级会员'}}</div>
					</div>
					<div class="user_phone">{{userInfo.mobile}}</div>
				</div>
			</div>
			<div class="top_icon iconfont icon-xiangyou1"></div>
		</div>
		<div class="Kanban_list">
			<div class="Kanban_item">
				<div class="Kanban_num">{{summaryInfo.pendingRebate ? summaryInfo.pendingRebate/100 : 0}}</div>
				<div class="Kanban_title">待返款</div>
			</div>
			<div class="Kanban_item">
				<div class="Kanban_num">{{summaryInfo.share_total ? summaryInfo.share_total/100 : 0}}</div>
				<div class="Kanban_title">累计分润</div>
			</div>
			<div class="Kanban_item">
				<div class="Kanban_num">{{summaryInfo.reward_total ? summaryInfo.reward_total/100 : 0}}</div>
				<div class="Kanban_title">累计奖励</div>
			</div>
			<div class="Kanban_item">
				<div class="Kanban_num">{{summaryInfo.income ? summaryInfo.income/100 : 0}}</div>
				<div class="Kanban_title">累计收益</div>
			</div>
		</div>
		<van-notice-bar mode="link" @click="toUnreadInfo" v-if="unread">你有{{unread}}条未读消息</van-notice-bar>
		<div class="menu_box">
			<!-- <div class="menu_mode">
				<div class="menu_title">我的任务</div>
				<div class="menu_list">
					<div class="menu_item">
						<div class="menu_icon"></div>
						<div class="menu_name">全部</div>
					</div>
					<div class="menu_item">
						<div class="menu_icon"></div>
						<div class="menu_name">进行中</div>
					</div>
					<div class="menu_item">
						<div class="menu_icon"></div>
						<div class="menu_name">已关闭</div>
					</div>
				</div>
			</div> -->

			<div class="menu_mode">
				<div class="menu_title">我的报单</div>
				<div class="menu_list">
					<div class="menu_item" @click="toDeclaration(0)">
						<div class="menu_icon iconfont icon-quanbudingdan"></div>
						<div class="menu_name">全部</div>
					</div>
					<div class="menu_item" @click="toDeclaration(1)">
						<div class="menu_icon iconfont icon-tianshenpi"></div>
						<div class="menu_name">待审批</div>
					</div>
					<div class="menu_item" @click="toDeclaration(2)">
						<div class="menu_icon iconfont icon-tongguo"></div>
						<div class="menu_name">已通过</div>
					</div>
					<div class="menu_item" @click="toDeclaration(3)">
						<div class="menu_icon iconfont icon-dingdanbohui"></div>
						<div class="menu_name">已驳回</div>
					</div>
				</div>
			</div>

			<div class="menu_mode">
				<div class="menu_title">其他</div>
				<div class="menu_list">
					<div class="menu_item" @click="toVip">
						<div class="menu_icon iconfont icon-huiyuan"></div>
						<div class="menu_name">会员等级</div>
					</div>
					<div class="menu_item" @click="toWallet">
						<div class="menu_icon iconfont icon-qianbao"></div>
						<div class="menu_name">钱包</div>
					</div>
					<div class="menu_item" @click="toAwardDetail">
						<div class="menu_icon iconfont icon-shouzhimingxi"></div>
						<div class="menu_name">奖励明细</div>
					</div>
					<div class="menu_item" @click="toProfitSharing">
						<div class="menu_icon iconfont icon-mingxi"></div>
						<div class="menu_name">分润明细</div>
					</div>
					<div class="menu_item" @click="toInviteFriends">
						<div class="menu_icon iconfont icon-yaoqing1"></div>
						<div class="menu_name">邀请好友</div>
					</div>
						<div class="menu_item" @click="toInvitationList">
						<div class="menu_icon iconfont icon-haoyou"></div>
						<div class="menu_name">邀请列表</div>
					</div>
					<div class="menu_item" @click="toCustomerService">
						<div class="menu_icon iconfont icon-kefu1"></div>
						<div class="menu_name">联系客服</div>
					</div>
					<div class="menu_item">
						<div class="menu_icon iconfont icon-bangzhu"></div>
						<div class="menu_name">新手教学</div>
					</div>
					<div class="menu_item" @click="switchCompany" v-if="companyList.length>1">
						<div class="menu_icon iconfont icon-qiehuanchengshi"></div>
						<div class="menu_name">切换公司</div>
					</div>
					<div class="menu_item" @click="quitLogin">
						<div class="menu_icon iconfont icon-tuichu"></div>
						<div class="menu_name">退出登录</div>
					</div>
				</div>
			</div>
		</div>
		<!-- <button class="quit_buttom" @click="switchCompany" v-if="companyList.length>1">切换公司</button> -->
		<van-popup v-model="showPicker" position="bottom">
			<van-picker
				show-toolbar
				:columns="companys"
				:default-index="companyIndex"
				@confirm="onConfirm"
				@cancel="showPicker = false"
			/>
		</van-popup>
  </div>
</template>

<script>
	import { clearTokenThenToLogin } from '@/utils/auth';
	import { getUserUnread, getSummaryInfo, getCompany, getUserAuthorization } from '@/services/user';
	import { Dialog } from 'vant';
	import { mapState } from 'vuex';
	export default {
		data() {
			return {
				unread: "",
				summaryInfo: {
          pendingRebate: 0,
          share_total: 0,
          reward_total: 0,
          income: 0,
        },
				companyList: [],
				showPicker: false,
				companys: [],
				companyIndex: "",
				params: {},
				wxInfo:{},
			};
		},
		computed: {
			...mapState({
				userInfo: state => state.user.userInfo,
				companyInfo: state => state.user.companyInfo,
			}),
		},
		mounted() {
			this.params = this.$route.query
			console.log(this.userInfo, 'userInfo-------------------114');
			this.getUserUnread();
			this.getSummaryInfo();
			this.getCompany()
			if(this.userInfo.authorization_status != 1){
				let ua = navigator.userAgent.toLowerCase();
				let isWeixin = ua.indexOf('micromessenger') != -1;
				if (isWeixin) {
					this.getWxInfo()
				}
			}
		},
		methods: {
			getWxInfo(){
				if(!this.params.code){
					let href = window.location.href
					let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa4f0b80a1b75d33b&redirect_uri=http%3A%2F%2Flucaiwang.net%2Fuser&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
					window.location.href = url
				}else{
					getUserAuthorization({code: this.params.code}).then(res => {
							if (res && res.code === 0) {
								this.$store.dispatch('getUserDetail');
								this.$toast('授权成功');
							}else{
								this.$toast('授权失败');
							}
						}).catch(err=>{
							this.$toast('授权失败');
						})
				}
			},
			getUserUnread() {
				getUserUnread({}).then(res => {
					if (res && res.code === 0) {
						this.unread = res.data.total
					}
					console.log(res, 'getUserUnread--------res');
				});
			},
			getSummaryInfo() {
				getSummaryInfo({}).then(res => {
					if (res && res.code === 0) {
						this.summaryInfo = res.data
					}
					console.log(res, 'getSummaryInfo--------res');
				});
			},
			getCompany() {
				getCompany({}).then(res => {
					if (res && res.code === 0) {
						this.companyList = res.data
						this.companyList.map((item, index)=>{
							this.companys.push(item.name)
							if(item.id == localStorage.getItem('companyId')) {
								this.$store.commit('USERINFO_UPDATE', {
									companyInfo: item,
								});
								this.companyIndex = index
							}
						})
					}
					console.log(res, 'getCompany--------res');
				});
			},
			quitLogin() {
				clearTokenThenToLogin(this.companyInfo.id);
			},
			toDeclaration(index) {
				this.$router.push({
									path: '/declaration',
									query: {
										type: index,
									},
								});
			},
			toWallet() {
				this.$router.push('/wallet');
			},
			toProfitSharing() {
				this.$router.push('/profitSharing');
			},
			toInviteFriends() {
				this.$router.push('/inviteFriends');
			},
			toInvitationList() {
				this.$router.push('/invitationList');
			},
			toAwardDetail() {
				this.$router.push('/awardDetail');
			},
			toVip() {
				this.$router.push('/vip');
			},
			toUnreadInfo() {
				this.$router.push('/unreadInfo');
			},
			toUserInfo() {
				this.$router.push('/userInfo');
			},
			toCustomerService() {
				this.$router.push('/customerService');
			},
			switchCompany() {
				this.showPicker = true
			},
			onConfirm(e,index) {
				console.log(e,index)
				this.companyIndex = index
				Dialog.confirm({
				  title: '提示',
				  message: '是否确定切换到 '+e+' 公司',
				}).then(() => {
					console.log("确认")
					clearTokenThenToLogin(this.companyList[index].id);
					this.showPicker = false
				}).catch(() => {
					console.log("取消")
				});
			}
		},
	};
</script>
<style lang="less" scoped>
  .page_box{
    background-color: @bg-color;
    padding-bottom: 100px;
  }
	.mode_top{
		background: @main-color;
		height: 160px;
		padding: 0 30px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.top_left{
			display: flex;
			justify-content: flex-start;
			align-items: center;
			.user_avatar{
				height: 100px;
				width: 100px;
				border-radius: 50%;
			}
			.user_info{
				margin-left: 10px;
				.user_name{
					display: flex;
					justify-content: flex-start;
					align-items: center;
					line-height: 40px;
					font-size: 32px;
					font-weight: bold;
					color: #ffffff;
					.user_grade{
						font-size: 24px;
						line-height: 30px;
						height: 30px;
						font-weight: 400;
						border-radius: 20px 15px 15px 0;
						padding: 0 10px 0 20px;
						background-color: red;
						margin-left: 5px;
					}
				}
				.user_phone{
					line-height: 40px;
					font-size: 26px;
					color: #FFFFFF;
				}
			}
		}
		.top_icon{
			color: #ffffff;
			font-size: 32px;
			font-weight: bold;
		}
	}
	.Kanban_list{
		height: 120px;
		padding: 0 30px;
		background: #FFFFFF;
		display: flex;
		justify-content: space-around;
		align-items: center;
	}
	.Kanban_item{
		width: 24%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		.Kanban_num{
			color: #333333;
			font-size: 36px;
			font-weight: bold;
		}
		line-height: 50px;
		.Kanban_title{
			color: #666666;
			font-size: 24px;
			line-height: 40px;
		}
	}
	.menu_box{
		padding: 20px 30px;
		.menu_mode{
			border-radius: 16px;
			background: #FFFFFF;
			margin-bottom: 20px;
			.menu_title{
				height: 80px;
				line-height: 80px;
				padding-left: 20px;
				color: #333333;
				font-size: 28px;
				font-weight: bold;
				border-bottom: 1px solid #f1f1f1;
			}
			.menu_list{
				display: flex;
				padding: 20px 10px;
				justify-content: flex-start;
				align-items: center;
				flex-wrap: wrap;
				.menu_item{
					width: 25%;
					height: 130px;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					margin: 3px 0;
					.menu_icon{
						height: 70px;
						width: 70px;
						text-align: center;
						line-height: 70px;
						font-size: 50px;
						color: #666666;
						// background: #f1f1f1;
						border-radius: 8px;
					}
					.menu_name{
						height: 50px;
						line-height: 50px;
						font-size: 24px;
						color: #666666;
					}
				}
			}
		}
	}
	.quit_buttom{
		margin: 30px 30px;
		width: 690px;
		height: 98px;
		border: 1px solid #666666;
		border-radius: 8px;
		background-color:#ffffff;
		font-size: 28px;
		color: #666666;
	}
</style>
