<template>
  <div class="page_box">
		<van-sticky>
		  <van-search
		    v-model="searchValue"
		    show-action
		  	shape="round"
		    placeholder="请输入报单编号"
				@clear="clearSearch"
		  >
		    <template #action>
		      <div @click="onSearch">搜索</div>
		    </template>
		  </van-search>
			<van-tabs v-model="active" @click="clickTab" color="#1677FF" class="tabs_border">
				<van-tab v-for="(tabitem,tabindex) in tabList" :key="tabindex" :title="tabitem.name"></van-tab>
			</van-tabs>
		</van-sticky>
		<div class="list_box">
			<div class="list_item" v-for="(item,index) in resultList" :key="index" @click="toOrderDetail(item.id)">
				<img class="item_ydk" v-if="item.payment_status == 1" src="@/assets/images/ydk.png"/>
				<div class="item_top">
					<div class="top_left">报单编号：<span>{{item.report_number}}</span></div>
					<div class="item_type" :style="item.status == 0 ? 'color:#1677FF;' : item.status == 1 ? 'color:#07c160' : 'color:red;'">{{item.status == 0 ? "待审核" : item.status == 1 ? "已通过" : "已驳回"}}</div>
				</div>
				<div class="item_name">{{item.taskInfo && item.taskInfo.title}}</div>
				<div class="item_info">
					<div class="info_title">报单时间：</div>
					<div class="info_content">{{item.created_at}}</div>
				</div>
				<div class="item_info">
					<div class="info_title">收货地址：</div>
					<div class="info_content">{{item.taskInfo && item.taskInfo.address}}</div>
				</div>
				<div class="item_info">
					<div class="info_title">支付宝账号：</div>
					<div class="info_content">{{item.alipay_account}}</div>
				</div>
			</div>
		</div>
		<PagingTips :loading="loading" :hasMore="hasMore"></PagingTips>
  </div>
</template>

<script>
	import { getReportList } from '@/services/user';
	import PagingTips from "@/components/PagingTips"
	export default {
		components:{
			PagingTips
		},
		data() {
			return {
				searchValue: '',
				searchStatu: false,
				active: 0,
				tabList: [
					{ name: '全部' },
					{ name: '待审批' },
					{ name: '已通过' },
					{ name: '已驳回' },
				],
				page: 1,
				size: 10,
				resultList: [],
				loading: false,
				hasMore: true
			};
		},
		mounted() {
			let params = this.$route.query
			this.active = parseInt(params.type)
			this.resetData()
			window.addEventListener('scroll', this.onReachBottom);
		},
		destroyed() {
			window.removeEventListener('scroll', this.onReachBottom);
		},
		methods: {
			onReachBottom() {
				const { clientHeight, scrollHeight } = document.documentElement;
				// 移动端和 PC 不太一样
				const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
				if (clientHeight + scrollTop >= scrollHeight - 100) {
					this.getReportList()
				}
			},
			resetData() {
				this.searchValue = ''
				this.searchStatu = false,
				this.page = 1,
				this.loading = false,
				this.hasMore = true,
				this.resultList = []
				this.getReportList();
			},
			getReportList(){
				if (this.loading || !this.hasMore) {
					return
				}
				this.loading = true
				let form = {
					page: this.page,
        	pageSize: this.size,
					report_number: this.searchStatu ? this.searchValue : '',
				}
				if(this.active != 0){
					form.status = this.active - 1
				 }
				getReportList(form).then(res => {
					if (res && res.code === 0) {
						this.resultList = [...this.resultList,...res.data.resultList],
						this.page ++
						this.hasMore = this.resultList.length < res.data.total
						console.log(res)
					}
					this.loading = false
				});
			},
			onSearch() {
				this.searchStatu = true
				this.page = 1,
				this.loading = false,
				this.hasMore = true,
				this.resultList = []
				this.getReportList();
			},
			clearSearch(){
				this.searchValue = ""
				this.searchStatu = false
				this.resetData()
			},
			clickTab(e) {
				this.active = e;
				window.history.replaceState(null, "", this.$route.path+'?type='+e);
				this.resetData()
			},
			toOrderDetail(id) {
				this.$router.push({
					path: '/orderDetail',
					query: {
						id: id,
					},
				})
			},
		},
	};
</script>

<style lang="less" scoped>
	.page_box{

	}
	/deep/.van-field__value{
		display: flex;
		align-items: center;
	}
	/deep/.van-field__control{
		font-size: 28px;
		width: 500px;
		padding: 0 20px;
	}
	.tabs_border{
		border-bottom: 1px solid #f1f1f1;
	}
	.list_box{
		padding: 20px 30px;
		.list_item{
			padding: 20px;
			border-radius: 8px;
			background: #FFFFFF;
			margin-bottom: 20px;
			position: relative;
			.item_ydk{
				position: absolute;
				bottom: 20px;
				right: 20px;
				z-index: 1;
				width: 100px;
				height: 100px;
				opacity: 0.5;
			}
			.item_top{
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: 60px;
				border-bottom: 1px solid #f1f1f1;
				.top_left{
					font-size: 28px;
					color: #999999;
					span{
						color: #333333;
					}
				}
				.item_type{
					color: #07c160;
					font-size: 32px;
					font-weight: bold;
				}
			}
			.item_name{
				font-size: 32px;
				color: #333333;
				font-weight: 500;
				word-wrap:break-word;
				overflow: hidden;
				-webkit-line-clamp: 2;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				line-height: 1.3;
				margin: 10px 0;
			}
			.item_info{
				font-size: 26px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 5px 0;
				.info_title{
					color: #999999;
					width: 160px;
					line-height: 35px;
				}
				.info_content{
					color: #666666;
					line-height: 35px;
					width: 490px;
				}
			}
		}
	}
</style>
