import Vue from 'vue';
import '@/utils/flexible';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import './vant';
import './assets/iconfont/iconfont.css';
import dayjs from 'dayjs';

Vue.prototype.dayjs = dayjs; // 可以全局使用dayjs

router.beforeEach(async (to, from, next) => {
  // try { document.documentElement.scrollTop = 0; } catch (e) { console.error(e); }
  // try { document.body.scrollTop = 0; } catch (e) { console.error(e); }
  // // 兼容火狐浏览器
  // try { window.pageYOffset = 0; } catch (e) { console.error(e); }
	
	if(to.query.companyId) {
		localStorage.setItem('companyId', to.query.companyId);
	}
  if (!store.state.user.userInfo.mobile && to.path !== '/login' && to.path !== '/register' && to.path !== '/forgetPassword') {
    await store.dispatch('getUserDetail');
  }
  if (to.path == '/home' || to.path == '/user') {
	  store.commit('USERINFO_UPDATE', {
	    showTabBar: true,
	  });
  } else {
	  store.commit('USERINFO_UPDATE', {
	    showTabBar: false,
	  });
  }
  if (to.meta.title) {
	  document.title = to.meta.title;
  }
  next();
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
