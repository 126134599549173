<template>
  <div class="page_box">
    <div class="report_info" @click="toOrderDetail(reportInfo.id)">
      <div class="item_top">
        <div class="top_left">报单编号：<span>{{reportInfo.report_number}}</span></div>
        <div class="item_type" :style="reportInfo.status==0?'':reportInfo.status==1?'color: #07c160;':'color: red;'">{{reportInfo.status == 0 ? "待审核" : reportInfo.status == 1 ? "已通过" : "已驳回"}}</div>
      </div>
      <div class="item_name">{{reportInfo.taskInfo && reportInfo.taskInfo.title}}</div>
      <div class="item_info">
        <div class="info_title">任务编号：</div>
        <div class="info_content">{{reportInfo.report_number}}</div>
      </div>
      <div class="item_info">
        <div class="info_title">下单数：</div>
        <div class="info_content">{{reportInfo.order_num}}</div>
      </div>
      <div class="item_info">
        <div class="info_title">佣金：</div>
        <div class="info_content">{{reportInfo.taskInfo && (reportInfo.taskInfo.commission_price*reportInfo.order_num)/100}}</div>
      </div>
      <div class="item_info">
        <div class="info_title">成长值：</div>
        <div class="info_content">{{reportInfo.taskInfo && reportInfo.taskInfo.growth_value}}</div>
      </div>
			<img class="item_ydk" v-if="reportInfo.payment_status == 1" src="@/assets/images/ydk.png"/>
    </div>
    <div class="list_box">
      <div class="list_item">
        <div class="item_name">打款状态</div>
        <div class="item_content">{{reportInfo.status}}</div>
      </div>
      <div class="list_item">
        <div class="item_name">微信昵称</div>
        <div class="item_content">{{reportInfo.userInfo && reportInfo.userInfo.nickname}}</div>
      </div>
      <div class="list_item">
        <div class="item_name">支付宝账号</div>
        <div class="item_content">{{reportInfo.alipay_account}}</div>
      </div>
      <div class="list_item">
        <div class="item_name">支付宝真实姓名</div>
        <div class="item_content">{{reportInfo.alipay_realname}}</div>
      </div>
      <div class="list_item">
        <div class="item_name">紧急联系方式</div>
        <div class="item_content">{{reportInfo.userInfo && reportInfo.userInfo.mobile}}</div>
      </div>
      <div class="list_item">
        <div class="item_name">报单时间</div>
        <div class="item_content">{{reportInfo.created_at}}</div>
      </div>
      <div class="list_item">
        <div class="item_name">收货地址</div>
        <div class="item_content">{{reportInfo.taskInfo && reportInfo.taskInfo.address}}</div>
      </div>
      <div class="list_item">
        <div class="item_name">方案</div>
        <div class="item_content">{{program}}</div>
      </div>
      <div class="list_item">
        <div class="item_name">物流单号</div>
        <div class="item_content">{{reportInfo.logistics}}</div>
      </div>
      <div class="list_item" v-if="reportInfo.reject_reason">
        <div class="item_name">驳回理由</div>
        <div class="item_content">{{reportInfo.reject_reason}}</div>
      </div>
    </div>
    <div class="button_box">
			<button class="totask_button" type="button" @click="toTaskDetail(reportInfo.taskInfo && reportInfo.taskInfo.id)">查看任务详情</button>
			<button class="fill_again" v-if="reportInfo.status == 2" @click="toSubmitTask(reportInfo.taskInfo && reportInfo.taskInfo.id, 'again')">重新填写</button>
			<button class="del_button" v-if="reportInfo.status == 0" @click="toSubmitTask(reportInfo.taskInfo && reportInfo.taskInfo.id, 'edit')">编辑</button>
			<button class="del_button" v-if="reportInfo.status == 0 || reportInfo.status == 2" @click="deleteReport">删除</button>
		</div>
  </div>
</template>

<script>
	import { getReportDelete } from '@/services/home';
  import { getReportDetail } from '@/services/user';
	import { Dialog } from 'vant';
  export default {
    data() {
      return {
        reportId: "",
        reportInfo: {}
      };
    },
    computed: {
      program(){
				let yj = this.reportInfo.taskInfo && this.reportInfo.taskInfo.unit_price/100
				let dj = this.reportInfo.taskInfo && this.reportInfo.taskInfo.commission_price/100
				let sun = (yj+dj)*this.reportInfo.order_num
				let str = `(`+(this.reportInfo.taskInfo && this.reportInfo.taskInfo.unit_price)/100+`+`+(this.reportInfo.taskInfo && this.reportInfo.taskInfo.commission_price)/100+`)*`+this.reportInfo.order_num+`=`+ sun
				return str
			}
    },
    mounted() {
      let params = this.$route.query
			this.reportId = parseInt(params.id)
      this.getReportDetail()
    },
    methods: {
      getReportDetail(){
        getReportDetail({ id:this.reportId }).then(res => {
					if (res && res.code === 0) {
						console.log(res)
						this.reportInfo = res.data
					}
				});
      },
      toTaskDetail(id) {
        this.$router.push({
					path: '/taskDetail',
					query: {
						taskId: id,
					},
				})
      },
			deleteReport() {
				Dialog.confirm({
				  title: '提示',
				  message: '是否确定删除',
				}).then(() => {
					// console.log("确认")
					getReportDelete({id: this.reportInfo.id }).then(res => {
						if (res && res.code === 0) {
							this.$toast('删除成功');
							this.$router.back()
						}
						console.log(res, 'getUserUnread--------res');
					});
				})
				.catch(() => {
					// console.log("取消")
				});
				
			},
			toSubmitTask(id,type) {
				this.$router.push({
					path: '/submitTask',
					query: {
						taskId: id,
						statu: type,
            reportId: this.reportId
					},
				})
			},
    },
  };
</script>
<style lang="less" scoped>
	.page_box{
    padding-bottom: 130px;
	}
  .list_box{
    padding: 0 20px;
    margin: 20px;
    border-radius: 8px;
    background: #ffffff;
    .list_item{
      font-size: 26px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;
      border-bottom: 1px solid #f1f1f1;
      .item_name{
        color: #999999;
        width: 190px;
        line-height: 35px;
      }
      .item_content{
        color: #666666;
        line-height: 35px;
        max-width: 460px;
        word-break: break-all;
      }
    }
  }
  .button_box{
		position: fixed;
		width: 100vw;
		left: 0;
		bottom: 0;
		height: 130px;
		background: #FFFFFF;
		border-top: 1px solid #CCCCCC;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 30px;
		.totask_button{
			height: 98px;
			max-width: 690px;
			flex: 1;
			border-radius: 8px;
			color: #FFFFFF;
			font-size: 28px;
			background: @button-color;
		}
		.fill_again{
			height: 96px;
			width: 200px;
			border-radius: 8px;
			border: 1px solid #666666;
			border-radius: 8px;
			font-size: 28px;
			color: #666666;
			margin-left: 20px;
			background: #ffffff;
		}
		.del_button{
			height: 96px;
			width: 150px;
			border-radius: 8px;
			border: 1px solid #666666;
			border-radius: 8px;
			font-size: 28px;
			color: #666666;
			margin-left: 20px;
			background: #ffffff;
		}
	}
  .report_info{
    margin: 20px;
			padding: 20px;
			border-radius: 8px;
			background: #FFFFFF;
			margin-bottom: 20px;
			position: relative;
			.item_ydk{
				position: absolute;
				bottom: 20px;
				right: 20px;
				z-index: 1;
				width: 100px;
				height: 100px;
				opacity: 0.5;
			}
			.item_top{
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: 60px;
				border-bottom: 1px solid #f1f1f1;
				.top_left{
					font-size: 28px;
					color: #999999;
					span{
						color: #333333;
					}
				}
				.item_type{
					color: #1677FF;;
					font-size: 32px;
					font-weight: bold;
				}
			}
			.item_name{
				font-size: 32px;
				color: #333333;
				font-weight: 500;
				word-wrap:break-word;
				overflow: hidden;
				-webkit-line-clamp: 2;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				line-height: 1.3;
				margin: 10px 0;
			}
			.item_info{
				font-size: 26px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 5px 0;
				.info_title{
					color: #999999;
					width: 160px;
					line-height: 35px;
				}
				.info_content{
					color: #666666;
					line-height: 35px;
					max-width: 490px;
				}
			}
		}

</style>
