<template>
  <div class="page_box">
		<div class="list_box">
			<div class="list_item" v-for="(item, index) in inviteFriendsList" :key="index">
				<div>
					<div class="item_name">{{item.nickname}}</div>
					<div class="item_time">{{item.mobile}}</div>
				</div>
				<div class="item_ri">
					<div class="item_name" v-if="item.total_order_num">{{item.total_order_num}}<span>单</span></div>
					<div class="item_time">{{item.created_at}}</div>
				</div>
			</div>
		</div>
		<PagingTips :loading="loading" :hasMore="hasMore"></PagingTips>
  </div>
</template>

<script>
	import { getInviteList } from '@/services/user';
	import PagingTips from "@/components/PagingTips"
	export default {
		components:{
			PagingTips
		},
	  data() {
	    return {
	      page: 1,
	      size: 20,
	      inviteFriendsList: [],
				loading: false,
				hasMore: true
	    };
	  },
	  computed: {
	  },
	  mounted() {
	    this.getInviteList()
			window.addEventListener('scroll', this.onReachBottom);
	  },
	  destroyed() {
	  	window.removeEventListener('scroll', this.onReachBottom);
	  },
	  methods: {
	  	onReachBottom() {
	  		const { clientHeight, scrollHeight } = document.documentElement;
	  		// 移动端和 PC 不太一样
	  		const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
	  		if (clientHeight + scrollTop >= scrollHeight - 100) {
	  			this.getInviteList()
	  		}
	  	},
	    getInviteList(){
				if (this.loading || !this.hasMore) {
					return
				}
				this.loading = true
	      let form = {
					page: this.page,
	      	pageSize: this.size,
				}
				if(this.active != 0){
					form.status = this.active - 1
				 }
				getInviteList(form).then(res => {
					if (res && res.code === 0) {
						this.inviteFriendsList = [...this.inviteFriendsList,...res.data.resultList],
						this.page ++
						this.hasMore = this.inviteFriendsList.length < res.data.total
					}
					this.loading = false
				});
	    }
	  },
	};
</script>
<style lang="less" scoped>
	.page_box{
	}
	.list_box{
		padding: 20px;
	}
	.list_item{
		padding: 10px 20px;
		background: #FFFFFF;
		margin-bottom: 16px;
		border-radius: 8px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.item_name{
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			font-size: 30px;
			font-weight: 500;
			color: #333333;
			line-height: 50px;
			span{
				margin-left: 5px;
				font-size: 24px;
				color: #999999;
			}
		}
		.item_time{
			color: #999999;
			font-size: 24px;
			line-height: 40px;
		}
		.item_ri{
			text-align: right;
		}
	}
</style>
