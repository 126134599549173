<template>
  <div class="page_box">
		<div class="list_box">
			<div class="list_item" v-for="(item, index) in unreadList" :key="index">
				<van-field
						v-model="item.content"
						rows="1"
						:disabled=true
						autosize
						type="textarea"
						placeholder=""
					/>
        <div class="item_time">{{item.created_at}}</div>
			</div>
		</div>
		<PagingTips :loading="loading" :hasMore="hasMore"></PagingTips>
  </div>
</template>

<script>
  import { getUnreadList } from '@/services/user';
  import PagingTips from "@/components/PagingTips"
	export default {
		components:{
			PagingTips
		},
    data() {
      return {
        page: 1,
        size: 20,
        unreadList: [],
				loading: false,
				hasMore: true,
				info: `11.26返款方案如下

11.11财神天猫小护肝+大钙数量10固定返980
11.11财神唯品会港版惠氏4段数量10固定返1100
11.11财神天猫小粉丸数量10固定返380
11.11财神天猫港版美素3段*2数量3固定返910`
      };
    },
    computed: {
    },
    mounted() {
      this.getUnreadList()
    	window.addEventListener('scroll', this.onReachBottom);
		},
		destroyed() {
			window.removeEventListener('scroll', this.onReachBottom);
		},
		methods: {
			onReachBottom() {
				const { clientHeight, scrollHeight } = document.documentElement;
				// 移动端和 PC 不太一样
				const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
				if (clientHeight + scrollTop >= scrollHeight - 100) {
					this.getUnreadList()
				}
			},
      getUnreadList(){
				if (this.loading || !this.hasMore) {
					return
				}
				this.loading = true
        let form = {
					page: this.page,
        	pageSize: this.size,
				}
				if(this.active != 0){
					form.status = this.active - 1
				 }
				getUnreadList(form).then(res => {
					if (res && res.code === 0) {
						this.unreadList = [...this.unreadList,...res.data.resultList],
						this.page ++
						this.hasMore = this.unreadList.length < res.data.total
					}
					this.loading = false
				});
      }
    },
  };
</script>
<style lang="less" scoped>
	.page_box{
	}
	.list_box{
		padding: 20px;
	}
	.list_item{
		padding: 10px 20px;
		background: #FFFFFF;
		margin-bottom: 16px;
		border-radius: 8px;
		.item_name{
			width: 520px;
			font-size: 30px;
			font-weight: 500;
			color: #333333;
			line-height: 50px;
			word-break: break-all;
		}
		.item_time{
			color: #999999;
			font-size: 24px;
			line-height: 40px;
		}	
	}
	.van-cell{
		padding: 0;
	}
	/deep/.van-field__control:disabled{
		font-size: 26px;
		color: #666666;
		-webkit-text-fill-color: #666666;
	}
	.van-cell::after{
		border: none;
	}
</style>
