<template>
  <div class="page_box">
		<div class="service">{{companyInfo.service}}</div>
  </div>
</template>

<script>
	import { mapState } from 'vuex';
	export default {
		data() {
			return {
			};
		},
		computed: {
			...mapState({
				companyInfo: state => state.user.companyInfo,
			}),
		},
		mounted() {
		},
		methods: {
		},
	};
</script>
<style lang="less" scoped>
	.page_box{
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		.service{
			font-size: 28px;
			padding-bottom: 100px;
		}
	}
</style>
