<template>
  <div class="page_box">
		<div class="mode_top">
			<div>钱包余额(元)</div>
			<div class="draw_record" @click="toWithdrawRecord">提现记录<span class="record_icon iconfont icon-xiangyou1"></span></div>
		</div>
		<div class="wallet_over">
			{{SummaryWallet.balance ? SummaryWallet.balance/100 : 0}}
			<button class="withdraw_buttom" @click="toWithdraw">去提现</button>
		</div>
		<div class="Kanban_list">
			<div class="Kanban_item">
				<div class="Kanban_title">累计收益(元)</div>
				<div class="Kanban_num">{{SummaryWallet.income ? SummaryWallet.income/100 : 0}}</div>
			</div>
			<div class="Kanban_item">
				<div class="Kanban_title">提现中(元)</div>
				<div class="Kanban_num">{{SummaryWallet.withdrawing ? SummaryWallet.withdrawing/100 : 0}}</div>
			</div>
			<div class="Kanban_item">
				<div class="Kanban_title">累计提现(元)</div>
				<div class="Kanban_num">{{SummaryWallet.withdrawal ? SummaryWallet.withdrawal/100 : 0}}</div>
			</div>
		</div>
  </div>
</template>

<script>
	import { getSummaryWallet } from '@/services/user';
	import { mapState } from 'vuex';
	export default {
		data() {
			return {
				SummaryWallet: {
          balance: 0,
          income: 0,
          withdrawing: 0,
          withdrawal: 0,
        },
			};
		},
		computed: {
			...mapState({
				userInfo: state => state.user.userInfo,
			}),
		},
		mounted() {
			this.getSummaryWallet()
		},
		methods: {
			getSummaryWallet() {
				getSummaryWallet({}).then(res => {
					if (res && res.code === 0) {
						this.SummaryWallet = res.data
					}
					console.log(this.SummaryWallet, 'getSummaryWallet--------res');
				});
			},
			toWithdraw() {
				this.$router.push('/withdraw');
			},
			toWithdrawRecord() {
				this.$router.push('/withdrawRecord');
			}
		},
	};
</script>
<style lang="less" scoped>
	.page_box{
		background: @main-color;
		padding: 20px 30px;
		.mode_top{
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 60px;
			font-size: 28px;
			color: #FFFFFF;
			.draw_record{
				display: flex;
				height: 35px;
				align-items: center;
				justify-content: center;
				.record_icon{
					font-size: 28px;
				}
			}
		}
		.wallet_over{
			height: 60px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			font-size: 36px;
			font-weight: bold;
			color: #FFFFFF;
			.withdraw_buttom{
				height: 40px;
				font-size: 24px;
				background: #FFFFFF;
				color: #666666;
				font-weight: 400;
				padding: 0 20px;
				line-height: 40px;
				border-radius: 20px;
				margin-left: 30px;
			}
		}
		.Kanban_list{
			margin-top: 20px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.Kanban_item{
				text-align: left;
				color: #FFFFFF;
				.Kanban_title{
					font-size: 28px;
					line-height: 50px;
				}
				.Kanban_num{
					font-size: 32px;
					font-weight: 500;
					line-height: 50px;
				}
			}
		}
	}
</style>
