<template>
  <div class="page_box">
		<div class="code_box">
			<div class="qrcode" ref="qrCodeUrl"></div>
			<!-- <div class="save_hint">长按二维码可保存图片</div> -->
		</div>
		<div class="code_name">邀请码</div>
		<div class="invite_code">{{userInfo.invite_code}}</div>
		<button class="save_code" :data-clipboard-text="userInfo.invite_code" @click="copy('邀请码','.save_code')">复制邀请码</button>
		<button class="save_button" :data-clipboard-text="inviteUrl" @click="copy('邀请连接','.save_button')">复制邀请连接</button>

  </div>
</template>

<script>
	import QRCode from 'qrcodejs2'
	import { mapState } from 'vuex';
	import Clipboard from 'clipboard';
	import { Toast } from 'vant'
	export default {
	  data() {
	    return {
				qrcode: "",
				companyId: "",
	    };
	  },
	  computed: {
	    ...mapState({
	      userInfo: state => state.user.userInfo,
	    }),
			inviteUrl(){
				let str = `${window.location.origin}/register?inviteCode=${this.userInfo.invite_code}&companyId=${this.userInfo.company_id}`
				return str
			}
	  },
	  mounted() {
	    this.creatQrCode();
	  },
	  methods: {
	  	creatQrCode() {
				this.qrcode = new QRCode(this.$refs.qrCodeUrl, {
					// text: 'http://112.74.73.159:8000/register?inviteCode='+this.userInfo.invite_code, // 需要转换为二维码的内容
					text: `${window.location.origin}/register?inviteCode=${this.userInfo.invite_code}&companyId=${this.userInfo.company_id}`, // 需要转换为二维码的内容
					width: 120,
					height: 120,
					colorDark: '#000000',
					colorLight: '#ffffff',
					correctLevel: QRCode.CorrectLevel.H
				})
				console.log(this.$refs.qrCodeUrl)
			},
			copy(even,lei) {
				let clipboard = new Clipboard(lei)
				clipboard.on('success', e => {
					console.log(e,"e---------------111")
					Toast(even+' 复制成功');
					// 释放内存
					clipboard.destroy()
				})
				clipboard.on('error', e => {
					console.log(e,"e---------------222")
					Toast(even+' 复制失败');
					clipboard.destroy()
				})
			},
		},
	};
</script>
<style lang="less" scoped>
	.page_box{
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		background: #FFFFFF;
		margin: 50px 30px;
		border-radius: 8px;
		padding: 50px 30px;
	}
	.code_box{
		.qrcode{
			display: inline-block;
			/deep/img {
					width: 300px;
					height: 300px;
					background-color: #fff; //设置白色背景色
					padding: 30px; // 利用padding的特性，挤出白边
					box-sizing: border-box;
			}
		}
		.save_hint{
			line-height: 80px;
			font-size: 26px;
			color: #999999;
		}
	}
	.code_name{
		color: #666666;
		line-height: 60px;
	}
	.invite_code{
		line-height: 60px;
		font-size: 36px;
		font-weight: bold;
		margin-bottom: 10px;
	}
	.save_button{
		height: 90px;
		width: 600px;
		border-radius: 8px;
		color: #FFFFFF;
		font-size: 28px;
		margin-bottom: 20px;
		background: @button-color;
	}
	.save_code{
		height: 90px;
		width: 600px;
		border-radius: 8px;
		color:  @button-color;
		font-size: 28px;
		margin-bottom: 20px;
		background: #FFFFFF;
		border: 2px solid @button-color;
	}
	.save_button{
		height: 90px;
		width: 600px;
		border-radius: 8px;
		color: #FFFFFF;
		font-size: 28px;
		margin-bottom: 20px;
		background: @button-color;
		border: 2px solid @button-color;
	}

</style>
