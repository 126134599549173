<template>
  <div class="mode_box">
    <div class="title">找回密码</div>
    <div class="from_box">
		<span class="iconfont icon-31dianhua left_icon"></span>
      <input v-model="mobile" placeholder="请输入手机号" type="text">
    </div>
    <div class="from_box">
      <span class="iconfont icon-yanzhengma left_icon"></span>
			<div class="verify_box">
				<input class="verify_input" v-model="code" placeholder="请输入验证码" type="text">
				<van-button type="info" @click="getCode" :disabled="countdown>0" class="verify_buttom">获取验证码 {{countdown ? "("+countdown+")" : ""}}</van-button>
			</div>
    </div>
		<div class="from_box">
      <span class="iconfont icon-mima left_icon"></span>
      <input v-model="password" placeholder="请输入新密码" type="password">
    </div>
		<div class="from_box">
			<span class="iconfont icon-mima left_icon"></span>
			<input v-model="verifyPassword" placeholder="请再次输入新密码" type="password">
		</div>
    <button class="register_buttom" @click="submit">提交</button>
  </div>
</template>

<script>
	import { forget, getCode } from '@/services/user';
	export default {
		data() {
			return {
				mobile: '',
				code: '',
				password: '',
				verifyPassword: '',
				countdown: 0,
				companyId: "",
			};
		},
		mounted() {
		let params = this.$route.query
		if(params.companyId) {
			this.companyId = params.companyId
			localStorage.setItem('companyId', params.companyId);
		}else{
			this.companyId = localStorage.getItem('companyId');
		}
	},
		methods: {
			getCode() {
				if (!this.mobile) {
					this.$toast('请输入手机号');
					return;
				}
				if(this.countdown>0){
					return
				}
				this.countdown = 60;
				const time = setInterval(() => {
					this.countdown--;
					if (this.countdown < 1) {
						clearInterval(time);
					}
				}, 1000);
				getCode({
					mobile:this.mobile,
					company_id: this.companyId,
				}).then(res => {
					console.log(res);
					if (res && res.code === 0) {
						this.$toast('发送成功');
					}
				});
			},
			submit() {
				if (!this.mobile) {
						this.$toast('请输入手机号');
						return;
				}
				if (!this.code) {
					this.$toast('请输入验证码');
					return;
				}
				if (!this.password) {
						this.$toast('请输入密码');
						return;
				}
				if (!this.verifyPassword) {
						this.$toast('请再次输入密码');
						return;
				}
				if (this.password != this.verifyPassword) {
						this.$toast('两次密码不一致');
				}
				forget({
					mobile: this.mobile,
					code: this.code,
					password: this.password,
					company_id: 1,
				}).then(res => {
					console.log(res);
					if (res && res.code === 0) {
						this.$router.push('/login');
					}
				});
			},
		},
	};
</script>

<style lang="less" scoped>
	.mode_box{
	  background-color:@bg-color;
	  height: 100%;
	  padding-bottom: 15Vh;
	  display: flex;
	  flex-direction: column;
	  align-items: center;
	  justify-content: center;
	  .title{
	    font-size: 36px;
	    color: #333333;
	    height: 120px;
	  }
	  .from_box{
	    display: flex;
	    justify-content: space-between;
	    align-items: center;
	    width: 650px;
	    height: 100px;
			background-color: #ffffff;
			border: 1px solid #999999;
			border-radius: 16px;
			margin: 20px 0;
	    .left_icon{
	      display: flex;
	      align-items: center;
	      justify-content: center;
	      width: 50px;
				margin-left: 20px;
	      font-size: 32px;
	      color: #666666;

	    }
	    input{
	      width: 580px;
	      height: 96px;
	      padding: 0 20px;
	      font-size: 28px;
	      border: none;
	      border-radius: 30px;
	    }
			.verify_box{
				width: 580px;
	      height: 96px;
	     	display: flex;
	      align-items: center;
	      justify-content: space-between;
				.verify_input{
					width: 300px;
					height: 96px;
					display: flex;
					align-items: center;
					justify-content: space-between;
				}
				.verify_buttom{
					width: 250px;
					height: 60px;
					border: 0;
					border-radius: 8px;
					margin: 20px 0;
					background-color: @button-color;
					font-size: 26px;
					color: #ffffff;
					margin-right: 20px;
				}
			}
	  }
	  .register_buttom{
	    width: 650px;
	    height: 100px;
	    border: 0;
	    border-radius: 8px;
			margin: 20px 0;
	    background-color: @button-color;
	    font-size: 28px;
	    color: #ffffff;
	  }
	}
</style>
