<template>
  <div class="page_box">
		<div class="card_box">
			<van-swipe :loop="false" :initial-swipe="initialSwipe" indicator-color="#6a6a6a">
			  <van-swipe-item v-for="(item,index) in vipList" :key="index">
					<div class="card_item" :style="'background: '+item.bg+';'">
						{{item.name}}
						<div class="vip_icon iconfont icon-vip"></div>
						<div class="value_growth">成长值: {{userInfo.growth_value}}</div>
						<div class="vip_statu" v-if="index < userInfo.level">{{index==3?'已达最大等级':'已达成'}}</div>
						<div class="vip_statu" v-else>距达成长值：{{item.value-userInfo.growth_value}}</div>
					</div>
				</van-swipe-item>
			</van-swipe>
		</div>
		<div class="vip_rule">
			<div class="rule_top">
				<div class="rule_title">成长规则</div>
				<div class="growth_detail" @click="toGrowthDetails">成长明细<span class="iconfont icon-xiangyou1"></span></div>
			</div>
			<div>
				<table class="tab_box" cellspacing="0" cellpadding="0">
					<tr class="tab_top">
						<td>等级</td>
						<td>所需成长值</td>
					</tr>
					<tr v-for="(ritem,rindex) in vipList" :key="rindex">
						<td>{{ritem.name}}</td>
						<td>{{ritem.value}}</td>
					</tr>
				</table>
			</div>
		</div>
  </div>
</template>

<script>
	import { mapState } from 'vuex';
	export default {
		data() {
			return {
				initialSwipe: 0,
				vipList: [
					{name: "初级会员", bg: "#79a6ed", value: 0},
					{name: "中级会员", bg: "#d0d0d2", value: 100},
					{name: "高级会员", bg: "#dbb95f", value: 200},
				]
			};
		},
		computed: {
			...mapState({
				userInfo: state => state.user.userInfo,
			}),
		},
		mounted() {
			this.initialSwipe = this.userInfo.level-1
		},
		methods: {
			toGrowthDetails() {
				this.$router.push('/growthDetails');
			},
		},
	};
</script>
<style lang="less" scoped>
	.page_box{
	}
	.card_box{
		padding: 30px;
		background-color: #FFFFFF;
		.card_item{
			position: relative;
			height: 360px;
			margin: 0 20px;
			display: flex;
			color: #FFFFFF;
			font-weight: bold;
			font-size: 42px;
			align-items: center;
			justify-content: center;
			// width: 500px;
			border-radius: 16px;
			.vip_icon{
				position: absolute;
				top: 70px;
				width: 100%;
				font-size: 60px;
				text-align: center;
			}
			.value_growth{
				font-size: 24px;
				position: absolute;
				top: 220px;
				font-weight: 400;
			}
			.vip_statu{
				font-size: 26px;
				position: absolute;
				top: 260px;
				font-weight: 400;
			}
		}
	}
	/deep/.van-swipe__indicators{
		bottom: 40px;
	}
	.vip_rule{
		margin: 20px;
		background-color: #FFFFFF;
		padding: 20px 30px;
		border-radius: 8px;
		.rule_top{
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 60px;
			.rule_title{
				color: #333333;
				font-size: 32px;
				font-weight: bold;
			}
			.growth_detail{
				color: #666666;
				font-size: 28px;
				span{
					font-size: 28px;
					line-height: 35px;
				}
			}
		}
	}
	.tab_box{
		margin-top: 20px;
		width: 100%;
		text-align: center;
		.tab_top{
			background-color: #1677FF;
			color: #FFFFFF;
		}
		tr{
			height: 60px;
			color: #666666;
			font-size: 28px;
		}
	}
	table tr th, table tr td{
		border: 0.5px solid #CCCCCC;
	}
	table tr td{
		border-left: none!important;
		border-top: none!important;
		// border-bottom: none!important;
		// border-right: none!important;
	}
	table tr :first-child {
		border-left: 0.5px solid #CCCCCC !important;
	}
</style>
