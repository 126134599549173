<template>
  <div class="page_box">
		<div class="list_box">
			<div class="list_item" v-for="(item, index) in withdrawList" :key="index">
				<div class="space_between">
					<div>支付宝账号：{{item.alipay_account}}</div>
					<div>￥{{item.amount/100}}</div>
				</div>
				<div class="space_between item_bottom">
          <div>{{item.created_at}}</div>
          <div :style="item.status == 0 ? 'color:#1677FF': item.status == 1 ? 'color:#999999':'color:#FF3B30'">{{item.status == 0 ? '待审核': item.status == 1 ? '已通过':'已驳回'}}</div>
        </div>
			</div>
		</div>
		<PagingTips :loading="loading" :hasMore="hasMore"></PagingTips>
  </div>
</template>

<script>
  import { getWithdrawList } from '@/services/user';
  import PagingTips from "@/components/PagingTips"
	export default {
		components:{
			PagingTips
		},
    data() {
      return {
        page: 1,
        size: 20,
        withdrawList: [],
				loading: false,
				hasMore: true
      };
    },
    computed: {
    },
    mounted() {
      this.getWithdrawList()
    	window.addEventListener('scroll', this.onReachBottom);
		},
		destroyed() {
			window.removeEventListener('scroll', this.onReachBottom);
		},
		methods: {
			onReachBottom() {
				const { clientHeight, scrollHeight } = document.documentElement;
				// 移动端和 PC 不太一样
				const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
				if (clientHeight + scrollTop >= scrollHeight - 100) {
					this.getWithdrawList()
				}
			},
      getWithdrawList(){
				if (this.loading || !this.hasMore) {
					return
				}
				this.loading = true
        let form = {
					page: this.page,
        	pageSize: this.size,
				}
				getWithdrawList(form).then(res => {
					if (res && res.code === 0) {
						this.withdrawList = [...this.withdrawList,...res.data.resultList],
						this.page ++
						this.hasMore = this.withdrawList.length < res.data.total
					}
					this.loading = false
				});
      }
    },
  };
</script>
<style lang="less" scoped>
	.page_box{
	}
	.list_box{
		padding: 20px;
	}
	.list_item{
		padding: 10px 20px;
		background: #FFFFFF;
		margin-bottom: 16px;
		border-radius: 8px;
		.space_between{
			display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 28px;
      color: #333333;
      height: 40px;
		}
		.item_bottom{
      font-size: 24px;
      color: #999999;
    }
	}
</style>
