import { get, post } from '@/utils/request';

// 注册
export const register = (params) => post('/auth/register', params);

// 获取验证码
export const getCode = (params) => post('/auth/sms', params);

// 登录
export const login = (params) => post('/auth/login', params);

// 忘记密码
export const forget = (params) => post('/auth/forget', params);

// 获取未读消息数量
export const getUserUnread = (params) => get('/user/unread', params);

// 获取未读消息列表
export const getUnreadList = (params) => post('/user/unreadList', params);

// 获取报单列表
export const getReportList = (params) => post('/report/list', params);

// 获取报单详情
export const getReportDetail = (params) => post('/report/detail', params);

// 获取分润明细列表
export const getProfitShareList = (params) => post('/profit/shareList', params);

// 获取用户的邀请列表
export const getInviteList = (params) => post('/user/inviteList', params);

// 获取用户成长值列表
export const getGrowthList = (params) => post('/user/growthList', params);

// 获取奖励明细
export const getProfitRewardList = (params) => post('/profit/rewardList', params);

// 提现
export const getWithdrawIndex = (params) => post('/withdraw/index', params);

// 修改用户信息
export const getUserUpdate = (params) => post('/user/update', params);

// 钱包信息
export const getSummaryWallet = (params) => get('/summary/wallet', params);

// 个人中心统计
export const getSummaryInfo = (params) => get('/summary/info', params);

// 获取公司
export const getCompany = (params) => get('/config/company', params);

// 提现记录
export const getWithdrawList = (params) => post('withdraw/list', params);

// 微信授权
export const getUserAuthorization = (params) => post('user/authorization', params);