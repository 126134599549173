import Vue from 'vue';
import {
  Button,
  Toast,
  Field,
  Popup,
  Tabbar,
  TabbarItem,
  NoticeBar,
  Swipe,
  SwipeItem,
  Search,
  Sticky,
  Tab,
  Tabs,
  DropdownMenu,
  DropdownItem,
  Radio,
  RadioGroup,
  Calendar,
  Cell,
	Stepper,
	Dialog,
	Picker,
} from 'vant';

Vue.use(Button);
Vue.use(Toast);
Vue.use(Field);
Vue.use(Popup);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(NoticeBar);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Search);
Vue.use(Sticky);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Calendar);
Vue.use(Cell);
Vue.use(Stepper); 
Vue.use(Dialog);
Vue.use(Picker);
