import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/views/Login.vue';
import Register from '@/views/Register.vue';
import ForgetPassword from '@/views/ForgetPassword.vue';
import Home from '@/views/Home.vue';
import User from '@/views/user/index.vue';
import Declaration from '@/views/user/declaration.vue';
import Wallet from '@/views/user/wallet.vue';
import Withdraw from '@/views/user/withdraw.vue';
import Vip from '@/views/user/vip.vue';
import AwardDetail from '@/views/user/awardDetail.vue';
import ProfitSharing from '@/views/user/profitSharing.vue';
import InviteFriends from '@/views/user/inviteFriends.vue';
import CustomerService from '@/views/user/customerService.vue';
import Teaching from '@/views/user/teaching.vue';
import TaskDetail from '@/views/taskDetail.vue';
import SubmitTask from '@/views/submitTask.vue';
import OrderDetail from '@/views/user/orderDetail.vue';
import UnreadInfo from '@/views/user/unreadInfo.vue';
import UserInfo from '@/views/user/userInfo.vue';
import InvitationList from '@/views/user/invitationList.vue';
import WithdrawRecord from '@/views/user/withdrawRecord.vue';
import GrowthDetails from '@/views/user/growthDetails.vue';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  { path: '/', redirect: '/home' },
  { path: '/login', component: Login, meta: { title: '登录' } },
  { path: '/register', component: Register, meta: { title: '注册' } },
  { path: '/forgetPassword', component: ForgetPassword, meta: { title: '找回密码' } },
  { path: '/home', component: Home, meta: { title: '任务大厅', keepAlive: true } },
  { path: '/user', component: User, meta: { title: '个人中心'} },
  { path: '/declaration', component: Declaration, meta: { title: '我的报单' } },
  { path: '/wallet', component: Wallet, meta: { title: '钱包' } },
  { path: '/withdraw', component: Withdraw, meta: { title: '提现' } },
  { path: '/withdrawRecord', component: WithdrawRecord, meta: { title: '提现记录' } },
  { path: '/vip', component: Vip, meta: { title: '会员等级' } },
  { path: '/growthDetails', component: GrowthDetails, meta: { title: '会员等级' } },
  { path: '/awardDetail', component: AwardDetail, meta: { title: '奖励明细' } },
  { path: '/profitSharing', component: ProfitSharing, meta: { title: '分润明细' } },
  { path: '/inviteFriends', component: InviteFriends, meta: { title: '邀请好友' } },
  { path: '/customerService', component: CustomerService, meta: { title: '联系客服' } },
  { path: '/teaching', component: Teaching, meta: { title: '新手教学' } },
  { path: '/taskDetail', component: TaskDetail, meta: { title: '任务详情' } },
  { path: '/submitTask', component: SubmitTask, meta: { title: '填写报单' } },
  { path: '/orderDetail', component: OrderDetail, meta: { title: '报单详情' } },
  { path: '/unreadInfo', component: UnreadInfo, meta: { title: '未读消息' } },
  { path: '/userInfo', component:UserInfo, meta: { title: '个人信息' } },
  { path: '/invitationList', component:InvitationList, meta: { title: '邀请列表' } },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
