<template>
  <div id="app" class="app_box">
		<div class="page_body">
			<!-- <router-view></router-view> -->
			 <keep-alive>
			    <router-view v-if="$route.meta.keepAlive"></router-view>
				</keep-alive>
				<router-view v-if="!$route.meta.keepAlive"></router-view>
		</div>
		<div class="page_foot" v-if="showTabBar">
			<van-tabbar route>
				<van-tabbar-item replace to="/home" icon="home-o">首页</van-tabbar-item>
				<van-tabbar-item replace to="/user" icon="search">我的</van-tabbar-item>
			</van-tabbar>
		</div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      userInfo: {},
    };
  },
  computed: {
    ...mapState({
      showTabBar: state => state.user.showTabBar,
    }),
  },
  mounted() {
    console.log(this.showTabBar);
  },
  methods: {
  },
};
</script>

<style lang="less">
	@import '~@/assets/styles/global.less';
	.app_box{
		height: 100vh;
		width: 100vw;
		display: flex;
		flex-direction: column;
		.page_body{
			min-height: 100vh;
			background-color:@bg-color;
			// overflow: auto;
		}
	}
	.page_box{
		background-color:@bg-color;
	}
</style>
