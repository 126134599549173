<template>
  <div class="page_box">
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="(lbitem,lbindex) in homeInfo.banners" :key="lbindex">
				<img class="banner_img" :src="lbitem.url">
			</van-swipe-item>
    </van-swipe>
		<van-sticky>
		  <van-search
		    v-model="searchValue"
		    show-action
		  	shape="round"
				@clear="clearSearch"
		    placeholder="请输入搜索关键词"
		  >
		    <template #action>
		      <div @click="onSearch">搜索</div>
		    </template>
		  </van-search>
			<van-tabs v-model="active" @click="clickTab" color="#1677FF" class="tabs_border">
				<van-tab v-for="(tabitem,tabindex) in homeInfo.platforms" :key="tabindex" :title="tabitem.name"></van-tab>
			</van-tabs>
			<van-dropdown-menu>
			  <van-dropdown-item @change="changeBrokerageSort" title="佣金排序" v-model="brokerageSort" :options="brokerageOption" />
			  <van-dropdown-item @change="changeTaskStatusSort" title="任务排序" v-model="taskStatusSort" :options="taskStatusOption" />
			  <van-dropdown-item title="日期" ref="dataDropdown">
			    <van-radio-group @change="changeDateRadio" v-model="dateRadio" direction="horizontal">
			      <van-radio name="1">全部日期</van-radio>
			      <van-radio name="2">自定义日期</van-radio>
			    </van-radio-group>
					<van-cell v-if="dateRadio == '2'" title="选择日期" :value="taskDate" @click="calendarShow = true" is-link />
			  </van-dropdown-item>
			</van-dropdown-menu>
		</van-sticky>

		<div class="list_box">
			<div class="list_item" v-for="(item,index) in taskList" :key="index" @click="toTaskDetail(item.id)">
				<div class="item_name">{{item.title}}</div>
				<div class="item_info">
					<div class="info_left">
						<div class="left_item">发布时间：<span>{{item.shelf_at}}</span></div>
						<div class="left_item">任务平台：<span>{{item.platform_info.name}}</span></div>
						<div class="left_item">任务佣金：<span class="item_nun">{{item.commission_price/100}}</span></div>
					</div>
					<div class="list_statu" :style="item.status!=1?'color: red;':''">{{item.status==1?"进行中":"已关闭"}}</div>
				</div>
			</div>
		</div>
		<PagingTips :loading="loading" :hasMore="hasMore"></PagingTips>
		<van-calendar v-model="calendarShow" :min-date="minDate" :default-date="defaultDate" :max-date="maxDate" @confirm="onConfirmTaskDate" />
  </div>
</template>

<script>
	import { getHomeInfo, getTaskList } from '@/services/home';
	import { mapState } from 'vuex';
	import PagingTips from "@/components/PagingTips"
	export default {
		components:{
			PagingTips
		},
		data() {
			return {
				userInfo: {},
				searchValue: '',
				searchStatu: false,
				active: 0,
				banners: [],
				platforms: [],
				brokerageSort: "",
				brokerageOption: [
					{ text: '默认排序', value: "" },
					{ text: '升序', value: "ASC" },
					{ text: '降序', value: "DESC" },
				],
				taskStatusSort: "",
				taskStatusOption: [
					{ text: '默认排序', value: "" },
					{ text: '进行中', value: 1 },
					{ text: '已关闭', value: 2 },
				],
				dateRadio: '1',
				minDate: new Date('2020-01-01'),
				maxDate: new Date(),
				defaultDate: new Date,
				taskDate: "",
				calendarShow: false,
				page: 1,
				size: 10,
				loading: false,
				hasMore: true,
				scroll: "",
			};
		},
		computed: {
			...mapState({
				homeInfo: state => state.home.homeInfo,
				taskList: state => state.home.taskList,
			}),
		},
		mounted() {
			if (!this.homeInfo.banners || this.homeInfo.platforms) {
				this.getCarouselData();
			}
			window.addEventListener('scroll', this.onReachBottom);
		},
		destroyed() {
			window.removeEventListener('scroll', this.onReachBottom);
		},
		activated() {
			if(this.scroll > 0){
			 window.scrollTo(0, this.scroll);
			 this.scroll = 0;
			 window.addEventListener('scroll', this.onReachBottom);
			}
		},
		deactivated(){
		  window.removeEventListener('scroll', this.onReachBottom);
		},
		methods: {
			onReachBottom() {
				const { clientHeight, scrollHeight } = document.documentElement;
				// 移动端和 PC 不太一样
				const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
				if (clientHeight + scrollTop >= scrollHeight - 100) {
					this.getTaskList();
				}
				this.scroll = scrollTop;
			},
			onSearch() {
				this.searchStatu = true
				this.page = 1,
				this.loading = false,
				this.hasMore = true,
				this.$store.commit('USERINFO_UPDATE', {
					taskList: [],
				});
				this.getTaskList();
			},
			clearSearch(){
				this.searchValue = ""
				this.searchStatu = false
				this.resetData()
			},
			clickTab(e) {
				this.active = e;
				this.resetData()
			},
			resetData() {
				this.searchValue = ''
				this.searchStatu = false,
				this.page = 1,
				this.loading = false,
				this.hasMore = true,
				this.$store.commit('USERINFO_UPDATE', {
					taskList: [],
				});
				this.getTaskList();
			},
			getCarouselData() {
				getHomeInfo({}).then(res => {
					// console.log(res);
					if (res && res.code === 0) {
						res.data.platforms.unshift({ id:"", name:"全部"})
						this.$store.commit('USERINFO_UPDATE', {
							homeInfo: res.data,
						});
						this.resetData();
					}
				});
			},
			getTaskList() {
				if (this.loading || !this.hasMore) {
					return
				}
				this.loading = true
				const fromData = {
					page: this.page,
					pageSize: this.size,
					title: this.searchStatu ? this.searchValue : '',
					platform_id: this.homeInfo.platforms[this.active].id,
					sort_commission: this.brokerageSort,
					shelf_at: this.dateRadio==2 ? this.taskDate : "",
				};
				if(this.taskStatusSort){
					fromData.status = this.taskStatusSort
				}
				getTaskList(fromData).then(res => {
					// console.log(res);
					if (res && res.code === 0) {
						this.$store.commit('USERINFO_UPDATE', {
							taskList: [...this.taskList,...res.data.resultList],
						});
						this.page ++
						this.hasMore = this.taskList.length < res.data.total
					}
					this.loading = false
				});
			},
			changeBrokerageSort(e) {
				this.brokerageSort = e;
				this.resetData();
			},
			changeTaskStatusSort(e) {
				this.taskStatusSort = e;
				this.resetData();
			},
			changeDateRadio(e) {
				console.log(e);
				this.dateRadio = e
				if(this.dateRadio == 1){
					console.log(this.$refs.dataDropdown)
					this.$refs.dataDropdown.toggle(false)
					this.resetData();
				}
			},
			onConfirmTaskDate(date) {
				console.log(date);
				this.calendarShow = false;
				this.taskDate = this.dayjs(date).format('YYYY-MM-DD');
				this.resetData();
			},
			toTaskDetail(id) {
				this.$router.push({
					path: '/taskDetail',
					query: {
						taskId: id,
					},
				})
			},
		},
	};
</script>
<style lang="less" scoped>
  .page_box{
		padding-bottom: 130px;
	}
	.my-swipe .van-swipe-item {
		height: 300px;
		display: flex;
		justify-content: center;
		align-items: center;
    background-color: #39a9ed;
  }
	.banner_img{
		width: 100%;
		height: 100%;
	}
	.tabs_border{
		border-bottom: 1px solid #f1f1f1;
	}
	/deep/.van-field__value{
		display: flex;
		align-items: center;
	}
	/deep/.van-field__control{
		font-size: 28px;
		width: 500px;
		padding: 0 20px;
	}
	.list_box{
		padding: 20px 30px;
		.list_item{
			padding: 20px;
			border-radius: 8px;
			background: #FFFFFF;
			margin-bottom: 20px;
			.item_name{
				font-size: 32px;
				color: #333333;
				font-weight: 500;
				word-break: break-all;
				overflow: hidden;
				-webkit-line-clamp: 2;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				line-height: 1.3;
			}
			.item_info{
				margin-top: 10px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				.info_left{
					font-size: 26px;
					color: #999999;
					.left_item{
						line-height: 40px;
						span{
							color: #666666;
						}
						.item_nun{
							color: #FF3B30;
							font-size: 32px;
							line-height: 40px;
						}
					}
				}
				.list_statu{
					font-size: 28px;
					color: #1677FF;
					font-weight: bold;
				}
			}
		}
	}
	/deep/.van-radio-group{
		height: 100px;
		padding: 0 32px;
	}
	/deep/.van-calendar {
		height: 90%
	}
</style>
