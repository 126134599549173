<template>
	<div class="prompt-box">
		<div class="prompt_content" v-if="hasMore && !loading">上拉加载更多</div>
		<div class="prompt_content" v-if="loading">加载中</div>
		<div class="prompt_content" v-if="!hasMore">没有更多数据了</div>
	</div>
</template>

<script>
	export default {
		props: {
		  loading: { 
		    type: Boolean
		  },
			hasMore: {
				type: Boolean
			},
		},
		data() {
			return {
			};
		},
		computed: {
		},
		mounted() {
		},
		methods: {
		},
	};
</script>

<style lang="less" scoped>
	.prompt-box{
		height: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
		.prompt_content{
			font-size: 26px;
			color: #999999;
		}
	}
</style>
