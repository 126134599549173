import { getUserInfo } from '@/services';
import { clearTokenThenToLogin } from '@/utils/auth';
const state = {
  showTabBar: false,
  userInfo: {},
	companyInfo: {},
};

const mutations = {
  /*
  * 更新各个state变量
  */
  USERINFO_UPDATE(stateNew, payload) {
    Object.keys(payload).forEach((key) => {
      stateNew[key] = payload[key];
    });
  },
};

const actions = {
  async getUserDetail({ commit }) {
    const res = await getUserInfo();
    if (res && res.code === 0 && res.data) {
			if(res.data.company_id != localStorage.getItem('companyId')) {
				clearTokenThenToLogin(localStorage.getItem('companyId'))
			}else{
				commit('USERINFO_UPDATE', {
				  userInfo: res.data || {},
				});
			}
    }
    return res;
  },
};

export default {
  state,
  actions,
  mutations,
};
