import { get, post } from '@/utils/request';

// 获取首页信息
export const getHomeInfo = () => get('/config/home');

// 获取任务列表
export const getTaskList = (params) => post('/task/list',params);

// 获取任务详情
export const getTaskDetail = (params) => post('/task/detail',params);

// 提交报单
export const getReportAdd = (params) => post('/report/add',params);

// 更新报单
export const getReportUpdate = (params) => post('/report/update',params);

// 删除报单
export const getReportDelete = (params) => post('/report/delete',params);
