<template>
  <div class="page_box">
		<div class="form_box">
			<van-field label-width="100px" v-model="alipayAccount" label="支付宝账号" placeholder="请输入支付宝账号" />
			<van-field label-width="100px" v-model="alipayRealname" label="支付宝真实姓名" placeholder="请输入支付宝真实姓名" />
			<van-field label-width="100px" v-model="amount" label="提现金额" placeholder="请输入提现金额" />
			<div class="from_item">钱包余额：<span class="over_num">￥{{userInfo.balance/100}}</span></div>
		</div>
		<div class="button_box">
			<button class="save_button" type="button" @click="submit">立即提现</button>
		</div>
  </div>
</template>

<script>
	import { getWithdrawIndex } from '@/services/user';
	import { Dialog } from 'vant';
	import { mapState } from 'vuex';
	export default {
		data() {
			return {
				alipayAccount: "",
				alipayRealname: "",
				amount: "",
			};
		},
		computed: {
			...mapState({
				userInfo: state => state.user.userInfo,
			}),
		},
		mounted() {
			this.setData()
		},
		methods: {
			setData() {
				console.log(this.userInfo)
				if(this.userInfo.alipay_realname){
					this.alipayRealname = this.userInfo.alipay_realname
				}
				if(this.userInfo.alipay_account){
					this.alipayAccount = this.userInfo.alipay_account
				}
			},
			submit() {
				if (!this.alipayAccount) {
				  this.$toast('请输入支付宝账号');
				  return;
				}
				if (!this.alipayRealname) {
				  this.$toast('请输入支付宝真实姓名');
				  return;
				}
				let isNum=/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
				if(!isNum.test(this.amount)) {
				  this.$toast('请输入正确的金额');
				  return;
				}
				if(this.amount > this.userInfo.balance/100) {
				  this.$toast('余额不足');
				  return;
				}
				Dialog.confirm({
				  title: '提示',
				  message: '是否确定提现',
				}).then(() => {
					console.log("确认")
					let form = {
						amount: this.amount*100,
						alipay_account: this.alipayAccount,
						alipay_realname: this.alipayRealname,
					}
					getWithdrawIndex(form).then(res => {
						if (res && res.code === 0) {
							this.$store.dispatch('getUserDetail');
							this.$toast('提现成功');
							this.$router.back()
						}
						console.log(res, 'getUserUnread--------res');
					});
				})
				.catch(() => {
					console.log("取消")
				});
			}
		},
	};
</script>
<style lang="less" scoped>
	.page_box{
		padding: 20px;
		.form_box{
			background: #FFFFFF;
			border-radius: 8px;
			padding: 20px;
			.from_item{
				line-height: 60px;
				padding: 0 30px;
				color: #999999;
				.over_num{
					color: #666666;
				}
			}
		}
	}
	/deep/.van-cell{
		align-items: center;
	}
	.button_box{
		position: fixed;
		width: 100vw;
		left: 0;
		bottom: 0;
		height: 130px;
		background: #FFFFFF;
		border-top: 1px solid #CCCCCC;
		display: flex;
		justify-content: center;
		align-items: center;
		.save_button{
			height: 98px;
			width: 690px;
			border-radius: 8px;
			color: #FFFFFF;
			font-size: 28px;
			background: @button-color;
		}
	}
</style>
