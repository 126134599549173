<template>
  <div class="page_box">
		<div class="form_box">
			<van-field label-width="100px" v-model="nickname" label="微信昵称" placeholder="请输入微信昵称" />
			<van-field label-width="100px" v-model="qq" label="QQ号" placeholder="请输入QQ号" />
			<van-field label-width="100px" readonly  v-model="userInfo.mobile" label="联系电话" placeholder="请输入联系电话" />
			<van-field label-width="100px" v-model="alipayAccount" label="支付宝账号" placeholder="请输入支付宝账号" />
			<van-field label-width="100px" v-model="alipayRealname" label="支付宝真实姓名" placeholder="请输入支付宝真实姓名" />
		</div>
		<div class="button_box">
			<button class="save_button" type="button" @click="saveInfo">保存信息</button>
		</div>
  </div>
</template>

<script>
	import { getUserUpdate } from '@/services/user';
	import { Dialog } from 'vant';
	import { mapState } from 'vuex';
	export default {
		data() {
			return {
				nickname: "",
				alipayRealname: "",
				alipayAccount: "",
				qq: "",
			};
		},
		computed: {
			...mapState({
				userInfo: state => state.user.userInfo,
			}),
		},
		mounted() {
			this.setData()
		},
		methods: {
			setData() {
				console.log(this.userInfo)
				if(this.userInfo.nickname){
					this.nickname = this.userInfo.nickname
				}
				if(this.userInfo.qq){
					this.qq = this.userInfo.qq
				}
				if(this.userInfo.alipay_realname){
					this.alipayRealname = this.userInfo.alipay_realname
				}
				if(this.userInfo.alipay_account){
					this.alipayAccount = this.userInfo.alipay_account
				}
			},
			saveInfo() {
				Dialog.confirm({
				  title: '提示',
				  message: '是否确定保存信息',
				}).then(() => {
					console.log("确认")
					let form = {
						nickname: this.nickname,
						qq: this.qq,
						alipay_account: this.alipayAccount,
						alipay_realname: this.alipayRealname,
					}
					getUserUpdate(form).then(res => {
						if (res && res.code === 0) {
							console.log(this.$store)
              this.$store.dispatch('getUserDetail');
							this.$router.back()
						}
					});
				})
				.catch(() => {
					console.log("取消")
				});
			}
		},
	};
</script>
<style lang="less" scoped>
	.page_box{
		padding: 20px 20px 150px 20px;
		.form_box{
			background: #FFFFFF;
			border-radius: 8px;
			padding: 20px;
		}
	}
	/deep/.van-cell{
		align-items: center;
	}
	.button_box{
		position: fixed;
		width: 100vw;
		left: 0;
		bottom: 0;
		height: 130px;
		background: #FFFFFF;
		border-top: 1px solid #CCCCCC;
		display: flex;
		justify-content: center;
		align-items: center;
		.save_button{
			height: 98px;
			width: 690px;
			border-radius: 8px;
			color: #FFFFFF;
			font-size: 28px;
			background: @button-color;
		}
	}
</style>
