<template>
  <div class="page_box">
		<div class="shop_box">
			<div class="shop_top">任务编号：{{taskInfo.task_number}}</div>
			<div class="shop_title">{{taskInfo.title}}</div>
			<div class="shop_info">佣金:<span>{{taskInfo.commission_price/100}}</span></div>
		</div>

		<div class="form_box">
			<van-field
				:value="taskInfo.share_price/100"
				label="任务单价(元)"
				center
				readonly
			/>
			<van-field
				v-model="program"
				center
				label="回款方案"
				readonly
			/>
			<van-field
				:value="payBack"
				center
				label="回款总金额"
				readonly
			/>
			<van-field
				:value="taskInfo.address"
				center
				autosize
				type="textarea"
				label="下单地址"
				readonly
			/>
			<van-field
				name="stepper"
				label="下单总数">
			  <template #input>
			    <van-stepper @change="changeOrderNum" v-model="orderNum" min="1" integer />
			  </template>
			</van-field>
			<van-field
			  v-model="logistics"
			  autosize
			  label="物流单号"
			  type="textarea"
			  placeholder="请输入物流单号(多个时+隔开或者换行)"
			/>
			<!-- <van-field
				v-model="address"
				center
				label="下单地址"
			/> -->
			<van-field
				v-model="alipayAccount"
				label="支付宝账号"
				center
				placeholder="请输入支付宝账号"
			/>
			<van-field
				v-model="alipayRealname"
				label="支付宝真实姓名"
				center
				placeholder="请输入支付宝真实姓名"
			/>
			<van-field
			  v-model="remark"
			  autosize
			  label="备注"
			  type="textarea"
			  placeholder="请填写备注"
			/>
		</div>
		<div class="button_box">
			<button class="order_now" v-if="status=='edit'" type="button" @click="submitRecord">保存</button>
			<button class="order_now" v-else type="button" @click="submitRecord">提交</button>
		</div>
  </div>
</template>

<script>
	import { getTaskDetail, getReportAdd, getReportUpdate } from '@/services/home';
	import { getReportDetail } from '@/services/user';
	import { mapState } from 'vuex';
	export default {
		data() {
			return {
				taskId: "",
				taskInfo: {},
				text: 0,
				message: '',
				orderNum:"",
				money: "",
				logistics: "",
				address: "",
				alipayAccount: "",
				alipayRealname: "",
				remark: "",
				status: "",//是否再次提交
				reportId: "",
				reportNumber: "",
			};
		},
		computed: {
			...mapState({
			  userInfo: state => state.user.userInfo,
			}),
			payBack(){
				let yj = this.taskInfo.unit_price/100
				let dj = this.taskInfo.commission_price/100
				let sun = (yj+dj)*this.orderNum
				this.money = sun
				return sun
			},
			program(){
				let str = `(`+this.taskInfo.unit_price/100+`+`+this.taskInfo.commission_price/100+`)*`+this.orderNum+`=`+ this.money
				return str
			}
		},
		mounted() {
			let params = this.$route.query
			this.taskId = params.taskId
			this.setData()
			if(params.statu == "again" || params.statu == 'edit') {
				this.status = params.statu
				this.reportId = parseInt(params.reportId)
				this.getReportDetail()
			}
			this.getTaskDetail()
		},
		methods: {
			setData() {
				if(this.userInfo.alipay_realname){
					this.alipayRealname = this.userInfo.alipay_realname
				}
				if(this.userInfo.alipay_account){
					this.alipayAccount = this.userInfo.alipay_account
				}
			},
			getReportDetail(){
        getReportDetail({ id:this.reportId }).then(res => {
					if (res && res.code === 0) {
						console.log(res)
						this.logistics = res.data.logistics
						this.orderNum = res.data.order_num
						this.alipayAccount = res.data.alipay_account
						this.alipayRealname = res.data.alipay_realname
						this.remark = res.data.remark
					}
				});
      },
			submitRecord(){
				const r = /^\+?[0-9][0-9]*$/; // 正整数（可以以0打头）
				if (!this.orderNum || !r.test(this.orderNum) ) {
					this.$toast('请输入正确的下单数量');
					return;
				}
				if (!this.logistics) {
				  this.$toast('请输入物流单号');
				  return;
				}

        const list = ((this.logistics && this.logistics.split("+")) || []).reduce(function(ary, cur) {
          return ary.concat(cur.split("\n"));
        }, []);

        // 去重
        const newList = [...new Set(list)];

        if (newList.length !== list.length) {
          this.$toast("物流单号有重复");
          return;
        }

				if (!this.alipayAccount) {
				  this.$toast('请输入支付宝账号');
				  return;
				}
				if (!this.alipayRealname) {
				  this.$toast('请输入支付宝真实姓名');
				  return;
				}
				let form = {
					task_id: this.taskId,
					order_num: this.orderNum,
					logistics: this.logistics,
					alipay_realname: this.alipayRealname,
					alipay_account: this.alipayAccount,
					remark: this.remark,
				}
				if(this.status) {
					form.id = this.reportId
					getReportUpdate(form).then(res => {
						if (res && res.code === 0) {
							console.log(res)
							this.$toast(this.status=='edit'?'编辑成功':'提交成功');
							this.$router.back()
						}
					});
				}else {
					getReportAdd(form).then(res => {
						if (res && res.code === 0) {
							console.log(res)
							this.$toast('报单成功');
							this.$router.back()
						}
					});
				}
				console.log(form)
			},
			getTaskDetail(){
				getTaskDetail({ id:this.taskId }).then(res => {
					if (res && res.code === 0) {
						console.log(res)
						this.taskInfo = res.data
					}
				});
			},
			changeOrderNum(e){
				const r = /^\+?[0-9][0-9]*$/; // 正整数（可以以0打头）
				if (!r.test(e) ) {
					this.orderNum = 1
				}else{
					this.orderNum = e
				}
			},
		},
	};
</script>
<style lang="less" scoped>
	.page_box{
	}
	.shop_box{
		padding: 10px 20px;
		background: #FFFFFF;
		margin: 20px;
		border-radius: 8px;
		.shop_top{
			font-size: 28px;
			color: #999999;
			height: 80px;
			line-height: 80px;
			border-bottom: 1px solid #CCCCCC;
		}
		.shop_title{
			word-break: break-all;
			padding: 10px 0;
			font-weight: 500;
			line-height: 40px;
			font-size: 28px;
			color: #333333;
		}
		.shop_info{
			height: 60px;
			display: flex;
			align-items: center;
			color: #999999;
			font-size: 26px;
			span{
				margin-left: 20px;
				color: red;
				font-size: 32px;
			}
		}
	}
	.form_box{
		margin: 20px;
		border-radius: 8px;
		background: #FFFFFF;
	}
	/deep/.van-field__label{
		width: 200px;
	}
	/deep/.van-stepper{
		display: flex;
	}
	/deep/.van-stepper__input{
		width: 200px;
	}
	/deep/.van-field__control:read-only{
		color: #999999;
	}
	.button_box{
		position: fixed;
		width: 100vw;
		left: 0;
		bottom: 0;
		height: 130px;
		background: #FFFFFF;
		border-top: 1px solid #CCCCCC;
		display: flex;
		justify-content: center;
		align-items: center;
		.order_now{
			height: 98px;
			width: 690px;
			border-radius: 8px;
			color: #FFFFFF;
			font-size: 28px;
			background: @button-color;
		}
	}
</style>
