/* eslint-disable */
import axios from "axios";
import store from "@/store";
import { getToken } from '@/utils/auth';
const router = import("@/router");
import { Toast } from 'vant';

axios.defaults.baseURL = "/api";
axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";
axios.defaults.headers["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers["Cache-Control"] = "no-cache";
axios.defaults.headers["pragma"] = "no-cache";

let source = axios.CancelToken.source();

//请求添加token
axios.interceptors.request.use((request, options) => {
  if (request.loading) {
    Toast.loading({
      message: '加载中...',
      forbidClick: true,
      loadingType: 'spinner',
    });
  }
  if (getToken()) {
    request.headers['Authorization'] = getToken()
  }
  // request.headers["demo-auth"] = store.state.loginInfo ? store.state.loginInfo.userId : ""; // 已将userId保存在store中
  return request;
})

//切换页面取消请求
// axios.interceptors.request.use(request => {
//   request.cancelToken = source.token;
//   return request;
// });
// router.then(lib => {
//   lib.default.beforeEach((to, from, next) => {
//     source.cancel()
//     source = axios.CancelToken.source();
//     next()
//   })
// })

//登录过期跳转
axios.interceptors.response.use(response => {
  let data = response.data;
  if (
    [401].includes(data.code) && window.location.pathname !== '/login'
  ) {
    // 跳转到登录页面
    window.location.href = `${window.location.protocol}//${window.location.host}/login`;
  }
  return response;
})

//返回值解构
axios.interceptors.response.use(response => {
  // 接口相应后先清除所有的toast
  if (response.config.loading) Toast.clear();
  let data = response.data;
  let isJson = (response.headers["content-type"] || "").includes("json");
  if (isJson) {
    if (data.code === 0) {
      return Promise.resolve(data);
    }

    // 未登录态时不toast
    if (data.code !== 401) Toast.fail(data.message);

    return Promise.reject(
      data.message ||
      "网络错误"
    );
  } else {
    return data;
  }
}, err => {
  let isCancel = axios.isCancel(err);
  if (isCancel) {
    return new Promise(() => {});
  }
  Toast.fail('网络错误，稍后再试');
  return Promise.reject(err);
})

export function post(url, data, otherConfig) {
  const config = {
    // 默认展示loading
    loading: true,
    ...otherConfig,
  };
  return axios.post(url, data, config);
}

export function get(url, data, otherConfig) {
  const config = {
    // 默认展示loading
    loading: true,
    ...otherConfig,
  };
  return axios.get(url, { params: data, ...config });
}
