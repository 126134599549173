const state = {
  homeInfo: {},
  taskList: [],
};

const mutations = {
  /*
  * 更新各个state变量
  */
  USERINFO_UPDATE(stateNew, payload) {
    Object.keys(payload).forEach((key) => {
      stateNew[key] = payload[key];
    });
  },
};

const actions = {
};

export default {
  state,
  actions,
  mutations,
};
