export function clearTokenThenToLogin(id) {
  clearToken();
  toLogin(id);
}

export function loginSuccess(token) {
  localStorage.setItem('token', token);
}

export function getToken() {
  return localStorage.getItem('token');
}

export function clearToken() {
  localStorage.removeItem('token');
}

export function toLogin(id) {
  window.location.href = `${window.location.protocol}//${window.location.host}/login?companyId=`+id;
}
