<template>
  <div class="login_body">
    <div class="title">登录</div>
    <div class="from_box">
			<span class="iconfont icon-31dianhua left_icon"></span>
      <input v-model="mobile" placeholder="请输入手机号" type="text">
    </div>
    <div class="from_box">
      <span class="iconfont icon-mima left_icon"></span>
      <input v-model="password" placeholder="请输入密码" type="password">
    </div>
    <div class="forget_box">
      <div class="forget_pass" @click="toForgetPassword">忘记密码？</div>
    </div>
    <button @click="toLogin" class="login_buttom">登录</button>
    <button @click="toRegister" class="register_buttom">注册新账号</button>
  </div>
</template>
<script>
import { login } from '@/services/user';
import { loginSuccess } from '@/utils/auth';

export default {
  data() {
    return {
      mobile: '',
      password: '',
			companyId: "",
    };
  },
	mounted() {
		let params = this.$route.query
		if(params.companyId) {
			this.companyId = params.companyId
			localStorage.setItem('companyId', params.companyId);
		}else{
			this.companyId = localStorage.getItem('companyId');
		}
	},
  methods: {
    toForgetPassword() {
      this.$router.push('/forgetPassword');
    },
    toRegister() {
      this.$router.push('/register');
    },
    toLogin() {
      if (!this.mobile) {
        this.$toast('请输入手机号');
        return;
      }
      if (!this.password) {
        this.$toast('请输入密码');
        return;
      }
      login({
        mobile: this.mobile,
        password: this.password,
        company_id: this.companyId,
      }).then(res => {
        console.log(res, 'login----------res');
        if (res && res.code === 0) {
          loginSuccess(res.data.token);
          this.$router.push('/home');
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
  .login_body{
    background-color:@bg-color;
    height: 100%;
    padding-bottom: 15Vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title{
      font-size: 36px;
      color: #333333;
      height: 120px;
    }
    .from_box{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 650px;
      height: 100px;
			background-color: #ffffff;
			border: 1px solid #666666;
			border-radius: 16px;
			margin: 30px 0;
      .left_icon{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
				margin-left: 20px;
        font-size: 32px;
        color: #666666;

      }
      input{
        width: 580px;
        height: 96px;
        padding: 0 20px;
        font-size: 28px;
        border: none;
        border-radius: 30px;
      }
    }
    .forget_box{
      height: 40px;
      display: flex;
      justify-content: flex-end;
      width: 690px;
      .forget_pass{
        line-height: 40px;
        width: 160px;
        text-align: center;
        color: #666666;
      }
    }
    .login_buttom{
      margin: 30px 0;
      width: 650px;
      height: 100px;
      border: 0;
      border-radius: 8px;
      background-color: @button-color;
      font-size: 28px;
      color: #ffffff;
    }
    .register_buttom{
      margin: 30px 0;
      width: 650px;
      height: 100px;
      border: 0;
      border-radius: 8px;
      background-color: #f5f5f5;
      font-size: 28px;
      color: #666666;
      border: 2px solid #666666;
    }
  }
</style>
